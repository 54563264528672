.appoint {
  width: 94%;
  position: absolute;
  left: 0;
  @include flex-column-wrap;
  border-radius: 5px;
  z-index: 2;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0 20px;
  transition: top .3s, box-shadow .3s, opacity .3s;
  overflow: hidden;

  &:hover {
    //box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 8px;
    opacity: .9;
  }

  &.full {
    top: 0;

    &:hover {
      //top: calc(0% - 4px);
    }
  }

  &.mid {
    top: 50%;

    &:hover {
      //top: calc(49% - 4px);
    }
  }

  &.one-quarter {
    top: 25%;

    &:hover {
      //top: calc(24% - 4px)
    }
  }

  &.three-quarter {
    top: 75%;

    &:hover {
      //top: calc(74% - 4px);
    }
  }

  &.type-cut {
    background: $red-cut;
  }

  &.type-col {
    background: $red-color;

    &:before {
      background-color: $red;
    }
  }

  &.type-sty {
    background: $red-styling;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    background-color: $black;
    width: 5px;
    height: 100%;
  }

  h2,
  p {
    width: 100%;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  h2{
    &.quirky{
      position: relative;
      top:0.7rem;
    }
  }

  p {
    font-size: 1.4rem;

    &.hidden {
      display: none;
    }

    &.appoint-hour {
      position: absolute;
      left: 10px;
      top: 2px;
      width: max-content;
      font-size: 1.1rem;
      opacity: .5;
      .crown{
        svg{
          font-size:1.1rem;
          margin-right:4px;
          position:relative;
          top:-0.05rem;
        }
        
      }
    }
  }

  .change-size-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    background-color: blue;
    opacity: 0;
    cursor: ns-resize;
    width: 100%
  }

}