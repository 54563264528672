.hdslist-container {
  @include popup;
  cursor: default;
  color: $black;

  @media (orientation: landscape){
    display:none !important;
  }

  .hdslist-content {
    width: max-content;
    width: 95%;

    h2 {
      font-size: 2rem;
      margin-bottom: 20px;
    }

    ul {
      @include flex-wrap;
      justify-content: center;
      align-items: center;

      li {
        margin: 5px;

        button {
          @include button;
          background-color: $black;
          color: $white;
          border-color: $black;
          border-radius: 9999px;

          &:hover {
            border-color: $dark-grey;
            background-color: $dark-grey;
          }
        }
      }
    }

  }
}