.planning-row,
.hour-column {
  >li {
    height: 10rem;
    @include flex-column-wrap;
    justify-content: center;
    align-items: center;
    border-top: solid 1px $grey;
    border-bottom: solid 1px $grey;
    position: relative;
    overflow-y: visible;

    &.planning-name {
      height: max-content;
      min-height: 6rem;
    }

    &.planning-name-fixed{
      position:fixed;
      bottom:calc(2vh + 5px);
      //top:calc(2vh + 4rem);
      z-index:3;
      border:0;
      height:fit-content;
      padding:10px;
      transition:opacity .2s;
      transition-timing-function:linear;
      p{
        background:rgba(255,255,255,.8);
        padding:2px 5px;
        border:solid 1px $grey;
        border-radius:5px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        font-size:1.4rem;
        word-break: break-word;
        position:relative;
        transition:top .2s;
        top:0;
        transition-timing-function:linear;
      }
      &.invisible{
        opacity:0;
        p{
          top:5px;
        }
      }
    }

    .planning-subrow {
      width: 100%;
      height: 50%;
      background-color: $light-grey;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: not-allowed;

      .slot-type {
        font-size: 1.3rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }

      &:nth-child(1) {
        border-bottom: 1px solid $mid-grey;
      }

      &:active,
      &.drag-focused {
        border: solid 2px $red;
      }

      &.available {
        &.slot-salon {
          background-color: $white;
          cursor: pointer;
        }

        &:active,
        &.drag-focused {
          border-color: $cta-green;
        }

      }

      &.slot-ecole {
        .slot-type {
          background-color: $ecole;
        }

      }

      &.slot-stage {
        .slot-type {
          background-color: $stage;
        }
      }

      &.slot-vacances {
        .slot-type {
          background-color: $vacances;
          display: none;
        }

      }

      &.slot-arret {
        .slot-type {
          background-color: $arret;
          display: none;
        }
      }

      &.slot-ecole,
      &.slot-stage,
      &.slot-vacances,
      &.slot-arret {
        cursor: not-allowed;

        &:active {
          border-color: $red;

          &:nth-child(1) {
            .slot-type {
              left: 1px;
              top: 1px;
            }
          }


        }

        &:nth-child(1) {
          position: relative;

          .slot-type {
            position: absolute;
            left: 3px;
            top: 3px;
            border-radius: 5px;
            width: max-content;
            height: max-content;
            padding: 5px 10px;
            opacity: .5;
          }
        }

        &:nth-child(2) {
          .slot-type {
            opacity: 0;
          }
        }
      }
    }
  }

  &.hour-column {
    >li {
      &:nth-child(1) {
        height: max-content;
        min-height: 6rem;

      }

      .planning-subrow {
        background-color: $white;
        padding: 0 10px;
        cursor: default;

        &:nth-child(1):active {
          border-bottom: 1px solid $mid-grey;
        }

        &:active {
          border: 0;
        }

        p {
          font-size: 1.3rem;
        }
      }

    }
  }
}