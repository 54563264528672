#root {
  .recurrents-container {
    @include popup;
    color:$black;
    .recurrents-content{
      padding-bottom:40px;
      h2{
        font-size:2rem;
        margin-bottom:30px;
      }
      ul{
        @include flex-wrap;
        justify-content: center;
        width:75%;
        @media (orientation: portrait) {
          flex-direction: column;
          max-height: 80vh;
          align-items: center;
        }
        li{
          @include flex-column-wrap;
          justify-content: center;
          align-items: center;
          margin:10px 0;
          text-align: center;
          border-radius:5px;
          width:calc(33% - 16px);
          padding: 10px;
          margin:8px;
          background-color:white;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          @media (orientation: portrait) {
            width:100%;
            max-width: 300px;
          }
          &.empty{
            justify-content: center;
            width:100%;
            background-color:transparent;
            box-shadow: none;
          }
          button{
            @include flex-wrap;
            justify-content: center;
            align-items: center;
            color: $red;
            svg{
              margin:0 5px 0 0;
              position:relative;
              top:-1px;
            }
            &:hover{
              color:$black;
            }
          }
        }
      }
    }
  }
}