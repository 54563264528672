#root {
  .calendar-container {
    @include popup;
    color: $black;
    flex-direction: column;

    .calendar-content {
      padding: 40px 60px;
      width: max-content;
    }

    button {
      color: $black;

      &.today {
        margin-top: 20px;
        font-size: 1.4rem;
        background-color: $red;
        color: white;
        @include button;
        border: 0;
        transition: opacity .3s;

        svg {
          height: 1.4rem;
          margin: 0 5px 0 0;
        }

        &:hover {
          opacity: .8;
        }
      }
    }

    h2 {
      font-size: 2rem;
      @include flex-wrap;
      width: 100%;
      justify-content: space-between;

      @media (orientation: portrait) {
        width: 75%;
      }

    }

    .articles-container {
      max-height: 32rem;
      overflow-y: auto;
      padding-right: 20px;
      padding-left: 20px;
      margin-top: 20px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: 0;
        border-radius: 10px;
        background-color: #F5F5F5;
        border: solid 1px $light-grey;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: 0;
        background-color: $grey;
      }

      .past {
        opacity: .5;

        .calendar {
          .calendar-row {
            li {
              color: $black;
            }
          }
        }
      }

      h3 {
        font-weight: normal;
        font-size: 1.8rem;
      }

      .calendar {
        @include flex-column-wrap;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 28rem;
        justify-content: center;

        &:last-child {
          margin-bottom: 0;
        }

        .calendar-row {
          @include flex-wrap;
          flex-wrap: nowrap !important;

          &.legend {
            >li {
              width: 4rem;
              height: 4rem;
              @include flex-wrap;
              justify-content: center;
              align-items: center;
            }
          }

          >li {
            button {
              width: 4rem;
              cursor: pointer;
              height: 4rem;
              border-radius: 999999px;
              transition: color 0s, background-color .2s !important;
              font-family: Hind;

              &:hover {
                background-color: $red;
                color: white;
              }
            }

            &.empty-day {
              opacity: 0;

              button {
                cursor: initial;
              }
            }
          }
        }
      }
    }

    .loader {
      margin: 40px 0 20px;
    }
  }
}