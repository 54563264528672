/* Fonts */

/* Paragraphes */
@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Regular.ttf);
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-SemiBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Hind;
  src: url(../assets/fonts/Hind-Bold.ttf);
  font-weight: 800;
}

/* Subtitles */
@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Regular.otf);
}

@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: Glacial Indifference;
  src: url(../assets/fonts/GlacialIndifference-Italic.otf);
  font-style: italic;
}

/* Titles */
@font-face {
  font-family: League Spartan;
  src: url(../assets/fonts/LeagueSpartan-Bold.otf);
}

/* Colors */

$red: #e21617;
$black: #191919;
$white: #f6f6f6;
$dark-grey: #777;
$grey: #ccc;
$mid-grey: #ddd;
$light-grey: #e7e7e7;
$ecole: #ffdf77;
$stage: #D185FF;
$vacances: #61EE78;
$arret: #FF9090;
$cta-red: #f9423a;
$cta-green: #00bb7e;
$cta-blue: #06a5ed;
$cta-orange: #ffb600;
$cta-cyan: #00bfb2;
$red-cut: rgba(25, 25, 25, .85);
$red-color: rgba(226, 22, 23, .85);
$red-styling: rgba(25, 25, 25, .85);
$postit: #FFFCAB;

/* Mixins */

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-column-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

@mixin button {
  padding: 10px 20px;
  border: solid 2px;
  color: white;
  font-size: inherit;
  border-radius: 5px;
  transition: background .2s, color .2s, border-color .2s;
  cursor: pointer;
}

@mixin popup {
  @include flex-wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin: 0;
  z-index: 4;
  transition: opacity .3s;
  opacity: 0;

  @media screen and (orientation: landscape) and (max-width: 680px) {
    align-items: flex-start;
    margin-top: 1rem !important;
  }

  > :nth-child(1) {
    background-color: $white;
    max-width: 800px;
    width: 90%;
    @include flex-column-nowrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
    top: 100px;
    transition: top .3s;
    border-radius: 5px;
    max-height: 100vh;

    @media screen and (orientation: landscape) and (max-width: 680px) {
      max-height: 75vh;
    }

    >form {
      overflow-y: auto !important;
    }

    @media (orientation: portrait) {
      width: 95% !important;
      padding: 30px 10px !important;
    }
  }

  &.visible {
    opacity: 1;

    > :nth-child(1) {
      top: 0;
    }
  }
}

@mixin popup-form {
  @include flex-column-wrap;
  margin-top: 10px;
  width: 500px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (orientation: portrait) {
    width: 100%;
  }

  .form-element {
    margin: 8px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include flex-column-wrap();

    input[type=text],
    input[type=time],
    input[type=date],
    input[type=email],
    select {
      padding: 4px 5px;
      text-align: center;
      font-family: Hind;
      font-size: 1.6rem;
      width: 90%;
      border-radius: 5px;
      border: solid 1px $dark-grey;
    }

    input[type=checkbox] {
      height: 1.6rem;
      width: 1.6rem;
    }

    label {
      margin-bottom: 5px;
      cursor: pointer;
    }

    .form-inputs {
      @include flex-wrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      input {
        width: max-content;
        margin: 0 10px;
      }
    }
  }

  .form-actions {
    @include flex-wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    button {
      @include flex-wrap;
      @include button;
      justify-content: center;
      align-items: center;
      border: solid 1px;
      border-radius: 9999px;
      background: transparent;
      margin: 0 5px;
      transition: color 0s, border-color 0s;

      &.next-button,
      &.create-button,
      &.add-button {
        font-weight: bold;
        color: $cta-green;

        &:focus {
          background-color: $cta-green;
          color: $white;
        }
      }

      &.edit-appoint {
        font-weight: bold;
        color: $cta-orange;

        &:focus {
          background-color: $cta-orange;
          color: $white;
        }
      }

      &.back-button {
        color: $black;
        opacity: .5;

        &:focus {
          background-color: rgba(0, 0, 0, .5);
          color: $white;
        }
      }

      &.close-button,
      &.remove-appoint {
        font-weight: bold;
        color: $cta-red;

        &:focus {
          background-color: $cta-red;
          color: $white;
        }
      }

      span {
        display: inline-block;
        width: 0;
        transition: width .2s, margin .2s;
        overflow: hidden;
        margin: 0;

        svg {
          width: 1.4rem;
          @include flex-wrap;
          justify-content: center;
          align-items: center;
        }
      }

      &:hover {
        span {
          width: 1.6rem;
        }

        &.next-button,
        &.create-button,
        &.remove-appoint,
        &.edit-appoint {
          span {
            margin: 0 0 0 7px;
          }
        }

        &.back-button,
        &.close-button,
        &.add-button {
          span {
            margin: 0 7px 0 0;
          }
        }
      }
    }
  }



}

/*@mixin for-large-screen {
  @media (min-width: 1001px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 550px) {
    @content;
  }
}*/

/* General */
#root {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  width: 100%;

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    align-items: center;
    color: $black;
    background-color: $white;
    font-family: Hind;
    font-size: 1.6rem;
    //background-color: #888;
    //background-color:#650b10;
    background-image: url(../assets/images/bgmotif2.png);
    /*background-image: url(../assets/images/bglasuite.png);*/
    /*background-size: cover;
    background-position: cover;*/
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    #root {
      height: 100%;
      width: 100%;
    }

    * {
      box-sizing: border-box;
      font-size: 1.6rem;
    }

    button {
      font-family: Hind;
    }

    main {
      @include flex-column-wrap;
      justify-content: center;
      padding: 0;
    }

    svg {
      pointer-events: none;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    textarea {
      resize: none;
      font-family: Hind;
      font-size: 1.6rem;
    }

    input {
      font-family: Hind;
    }

    .discreet-text {
      font-size: 1.5rem;
      color: $dark-grey;
    }
  }
}