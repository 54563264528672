.postit {
  position: fixed;
  background-color: $postit;
  width: 25rem;
  height: 20rem;
  border-radius: 5px;
  overflow: hidden;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 4;

  .postit-header {
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, .1);
    height: 3rem;
    cursor: move;

    button {
      cursor: pointer;
      background: transparent;
      position: absolute;
      right: 1rem;
      top: 0.7rem;
      border: 0;
      outline: 0;
      height: 1em;
      @include flex-wrap;
      justify-content: center;
      opacity: .5;
    }
  }

  textarea {
    width: calc(100% - 4rem);
    height: 15rem;
    max-height: 15rem;
    overflow-y: auto;
    margin: 1rem 2rem;
    border: 0 !important;
    outline: 0 !important;
    background: transparent;
    font-family: Hind;
    font-size: 1.6rem;
  }
}