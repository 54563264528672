.planning-column {
  >li {
    width: 100%;
    text-align: center;
    border-left: solid 1px $grey;
    border-right: solid 1px $grey;
    height: max-content;

    &:nth-child(1) {
      border-left: solid 2px $grey;
      width: max-content;
    }

    &:not(:nth-child(1)){
      max-width: 350px;
      @media (orientation: portrait) {
        display:none;
        max-width: none;
        width:100%;
        ul{
          width:100%;
        }
        &.mobile-visible{
          display:flex;
        }
      }
    }

    &:last-child {
      border-right: solid 2px $grey;
    }
  }
}