.create-container {
  @include popup;
  cursor: default;
  color: $black;

  .create-content {
    width: max-content;
    max-height: 75vh;

    h2 {
      font-size: 2rem;
    }

    form {
      @include popup-form;
      display: block;
      margin-top:5px;

      .recurrence{
        @include flex-wrap;
        justify-content: center;
        align-items: center;
        margin-bottom:10px;
        li{
          margin:5px;
          button{
            cursor:pointer;
            border-radius:5px;
            font-size:1.4rem;
            border:0;
            background-color:#fff;
            padding:2px 5px;
            transition:background-color .2s, color .2s;
            &:hover, &.selected{
              background-color:$black;
              color:$white;
            }
          }
        }
      }

      h3 {
        font-size: 1.8rem;
        opacity: .7;
        font-weight: normal;
        margin: 0 0 5px 0;
      }

      .steps {
        @include flex-nowrap;
        width: 200%;

        .step {
          width: 100%;
          @include flex-column-wrap;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          left: 0;
          transition: left .3s;
          padding: 0 20px;
          position: relative;

          .btn-divides {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            button {
              height: 2.8rem;
              width: 2.8rem;
              border: 0;
              cursor: pointer;
              margin: 0 5px;
              border-radius: 9999px;
              background-color: $black;
              color: white;
              transition: background-color .2s;

              &:hover {
                background-color: $dark-grey;
              }
            }
          }

          .divides-list {
            display: inline;
            margin: 8px 0 0;
            font-size: 1.3rem;

            strong {
              font-size: 1.3rem;
            }

          }

          .form-checkbox {
            flex-direction: row;
            justify-content: center;
            align-items: center;

            label {
              margin: 0 0 0 10px;
            }
          }
        }

        &.step-1 {
          .step {
            left: -50%;
          }
        }

        .search-box {
          margin-top: 10px;
          width: 100%;

          ul {
            @include flex-wrap;
            justify-content: center;
            align-items: center;
            width: 100%;

            li {
              width: calc(50% - 10px);
              margin: 5px;

              @media (orientation: portrait){
                width:80%;
              }

              &.add-client {
                @media (orientation: portrait){
                  width:max-content;
                  margin-top:20px;
                }
                button {
                  background-color: $black;
                  color: $white;
                  border-color: $black;

                  &:hover {
                    border-color: $dark-grey;
                    background-color: $dark-grey;
                  }
                }
              }

              button {
                @include button;
                padding: 10px 20px;
                border: dashed 1px $grey;
                background: transparent;
                color: $black;
                transition: background-color .2s, border-color .2s;
                width: 100%;
                border-radius: 9999px;

                &:hover {
                  background-color: $light-grey;
                }
              }
            }
          }

          .loader {
            margin-top: 10px;
          }
        }
      }

    }
  }
}