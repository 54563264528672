.edit-container {
  @include popup;
  cursor: default;
  color: $black;

  .edit-content {
    width: max-content;

    h2 {
      font-size: 2rem;
    }

    form {
      @include popup-form;
      display: block;
      h3 {
        font-size: 1.8rem;
        opacity: .7;
        font-weight: normal;
        margin: 0 0 5px 0;
      }
      .parent-appoint{
        margin-top: 20px;
        font-size:1.4rem;
        opacity:.6;
        *{
          font-size:1.4rem;
        }
        svg{
          margin-right:2px;
        }
      }
      .add-divide{
        @include flex-wrap;
        @include button;
        justify-content: center;
        align-items: center;
        border: dashed 1px;
        border-radius: 9999px;
        background: transparent;
        margin: 10px auto 0;
        transition: color 0s, border-color 0s;
        border-color:$black;
        color:$black;
        font-weight: bold;
        opacity:.6;
        font-size:1.4rem;
        span {
          display: inline-block;
          width: 0;
          transition: width .2s, margin .2s;
          overflow: hidden;
          margin: 0;
  
          svg {
            width: 1.4rem;
            @include flex-wrap;
            justify-content: center;
            align-items: center;
          }
        }
        &:hover{
          span {
            width:1.4rem;
            margin: 0 7px 0 0;
          }
        }
        
      }
    }
  }


}