html {
  body {

    #login {
      @include flex-column-wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $black;
      color: white;
      width: 100%;
      height: 100%;

      article {
        @include flex-column-wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: max-content;
        width: 90%;
        max-width: 800px;
        border: solid 1px $dark-grey;
        border-radius: 5px;
        padding: 20px;

        h1 {
          color: white;
          font-size: 3rem;
          font-family: League Spartan;
          margin-bottom: 20px;

          img {
            height: 40px;
          }
        }

        a {
          @include button;
          text-decoration: none;
          margin-top: 20px;
          display: block;
          width: max-content;
          border-width: 1px;

          &:hover {
            background-color: white;
            border-color: white;
            color: $black;
          }
        }
      }
    }


  }
}