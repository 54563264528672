html {
  body {
    height: 100vh;
    overflow-y: hidden;

    #home {
      height: calc(100vh - 2vw);
      overflow-y: hidden;
      border-bottom: 2px solid $grey;
      width: 98%;
      margin: 1vw auto 0;
      background-color: white;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      @media (orientation: portrait) {
        width: 100%;
        height: 100vh;
        margin: 0;
      }

      header {
        display: flex;
        height: 4rem;
        align-items: center;
        background-color: black;
        color: white;
        justify-content: space-between;
        padding: 0 20px;

        >div {
          display: flex;

          button {
            margin-right: 20px;
            color: $light-grey;

            &.arrow-day{
              svg{
                margin:0;
              }
            }

            @media (orientation: portrait) {
              margin: 8px;
            }

            svg {
              margin: 0;
            }
          }

          h1 {
            button {
              font-weight: 700;

              svg {
                margin-right: 10px;
              }
            }
          }
        }

        button,
        a {
          text-decoration: none;
          color: white;
          background: transparent;
          border: 0;
          outline: 0;
          cursor: pointer;
          transition: color .2s;

          svg {
            margin: 0 10px;
            pointer-events: none;
          }

          &:hover {
            color: $red;
          }
        }
      }

      main {

        .planning-column {
          display: flex;
          width: 100%;
          height: calc(100vh - 2vw - 4rem);
          overflow-y: auto;
        }

        .loading-calendar {
          background-color: $black;
          @include flex-column-wrap;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 5;

          p {
            color: white;
            margin-top: 10px;
          }
        }
      }
    }
  }
}